import React from 'react'
import {Link} from "react-router-dom";
import {ReactComponent as InfoSvg} from '../assets/info.svg';
import {connect} from "react-redux";
import { useTranslation } from "react-i18next";

import './WelcomePage.scss'

const ListItem = ({label, imageUrl, svgComponent, noStyle}) => {
    return <div className={`list-item-element ${noStyle ? 'element-no-style' : ''}`}>
        <div className="label">
            {label}
        </div>
        <div className="image">
            {svgComponent ? svgComponent : <img alt={label} src={imageUrl}/>}
        </div>
    </div>
}

const WelcomePage = ({ auth, customer, homepageSettings: { settings } }) => {
    const { t } = useTranslation();

    if(!settings) {
        return null
    }

    return <div className="welcome-page">
        <div className="welcome-menu-list">
            {auth.canPreorder ? <Link to="/app/presentation">
                <ListItem imageUrl={settings.homepage_image_1} label={t('Preorder New Collection')}/>
            </Link> : null }
            {
                ((customer && customer.activeCustomer && customer.activeCustomer.customer && customer.activeCustomer.customer.length && customer.activeCustomer.customer[0]
                        && customer.activeCustomer.customer[0].billingAddress && customer.activeCustomer.customer[0].billingAddress.softoneCountryCode === 1000) ||
                    ( auth.activeUser && auth.activeUser.allAccess && auth.activeUser.b2bView === 'GR' ) ||
                    ( auth.activeUser && auth.activeUser.role === 'admin' && auth.activeUser.b2bView === 'GR' ) ||
                    ( auth.activeUser && auth.activeUser.role === 'salesman' && auth.activeUser.softoneCountryCode === 1000 )
                ) && <Link to="/app/presentation?factory=Simone">
                    <ListItem imageUrl={settings.homepage_image_1} label={t('Preorder New Simone Collection')}/>
                </Link>
            }
            <Link to="/app/archive?factory=Vamp">
                <ListItem imageUrl={settings.homepage_image_2} label={t('Available Now Collection & Offers')}/>
            </Link>
            {
                ((customer && customer.activeCustomer && customer.activeCustomer.customer && customer.activeCustomer.customer.length && customer.activeCustomer.customer[0]
                && customer.activeCustomer.customer[0].billingAddress && customer.activeCustomer.customer[0].billingAddress.softoneCountryCode === 1000) ||
                    ( auth.activeUser && auth.activeUser.allAccess && auth.activeUser.b2bView === 'GR' ) ||
                    ( auth.activeUser && auth.activeUser.role === 'admin' && auth.activeUser.b2bView === 'GR' ) ||
                    ( auth.activeUser && auth.activeUser.role === 'salesman' && auth.activeUser.softoneCountryCode === 1000 )
                ) &&
                <Link to="/app/archive?factory=Simone">
                    <ListItem imageUrl={settings.simone_perele_buy_now_image} label="Available Now - Simone Perele"/>
                </Link>
            }

            <Link to={`${customer && customer.activeCustomer ? '/app/customer/account' : '/app/account'}`}>
                <ListItem imageUrl={settings.homepage_image_3} label={t('My Account – Information')}/>
            </Link>
        </div>
    </div>

}

const mapStateToProps = ({auth, customer, homepageSettings}) => {
    return {
        auth,
        customer,
        homepageSettings
    }
}

export default connect(mapStateToProps, {})(WelcomePage);