import React, { useState } from 'react'
import {ReactComponent as EyeSvg} from '../assets/eye.svg';
import { useTranslation } from "react-i18next";

import './LoginForm.scss'

const LoginForm = ({handleLogin, triedToAuth, authenticationFailedMessage}) => {
    const { t } = useTranslation();

    const [ showPinForm, setShowPinForm ] = useState(false)
    const [ passwordVisible, setPasswordVisible ] = useState(false)

    if(authenticationFailedMessage && authenticationFailedMessage.errorType === 'reauth' && !showPinForm ) {
        setShowPinForm(true)
    }

    return (
        <form method='POST' className='login-form' onSubmit={(e) => handleLogin(e)}>
            <div className="form-group">
                <label id='email' htmlFor="email">
                    {t('Email')}
                </label>
                <input aria-labelledby="email" required type="email" name="email"/>
            </div>
            <div className="form-group password-form-group">
                <label id="password" htmlFor="password">
                    {t('Password')}
                </label>
                <div className="input-container">
                    <input aria-labelledby="password" required type={`${passwordVisible ? 'text' : 'password'}`} name="password"/>
                    <span className="show-password-toggle" onClick={() => {
                        setPasswordVisible(!passwordVisible)
                    }}><EyeSvg/></span>
                </div>
            </div>
            {authenticationFailedMessage.errorMessage ? <p className="login-form-error">{authenticationFailedMessage.errorMessage}</p> : null}
            {showPinForm ?
                <div className="reauth-pin-container">
                    <p>We've sent you a new verification pin, please paste it below</p>
                    <input type="text" required={true} name="verificationCode" placeholder="pin..."/>
                </div>
            : null}
            <div className="form-group">
                <input className="submit-button" type="submit" value={t('Submit')}/>
            </div>
        </form>
    )

}

export default LoginForm
