import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import {Formik, Field, Form} from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

import {createCustomerAction, clearCustomerAfmExists, selectCustomerAction} from '../../actions/customerActions'

import FormSection from "./FormSection";
import FormElement from "./FormElement";
import SelectFromExistingCustomersPopup from "../Modals/SelectFromExistingCustomersPopup";

import './CustomersCreateForm.scss'
import FullScreenLoader from "../Common/FullScreenLoader";

class CustomersCreateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showShipping: false,
            submitProcess: false,
        }
    }

    render() {
        const { t } = this.props;
        const { submitProcess } = this.state

        const {
            customer: {countries, customersWithTheSameAFM},
            auth: {activeUser, userSalesmen},
            createCustomerAction,
            clearCustomerAfmExists,
            selectCustomerAction,
            potentialCustomer,
            history
        } = this.props

        console.log(activeUser)
        console.log(userSalesmen)

        let initialValues = {
            name: '',
            email: '',
            vat: '',
            ICCODE: '',
            billingAddress: '',
            billingCity: '',
            billingDistrict: '',
            billingZip: '',
            billingCountry: activeUser.softoneCountryCode,
            billingPhone: '',
            shippingAddress: '',
            shippingCity: '',
            shippingDistrict: '',
            shippingZip: '',
            shippingCountry: activeUser.softoneCountryCode,
            shippingPhone: '',
            alternateSalesman: activeUser.id,
            useDifferentShippingAddress: false
        }

        if(submitProcess) {
            return <FullScreenLoader message="We are processing your new customer, please wait" />
        }

        let yupShape = {
            name: Yup.string().required('Name is required'),
            billingAddress: Yup.string().required('Billing Address is required'),
            billingZip: Yup.string().required('Billing Zip is required'),
            billingCity: Yup.string().required('Billing City is required'),
        }

        if(this.state.showShipping) {
            yupShape.shippingAddress = Yup.string().required('Shipping Address is required')
            yupShape.shippingZip = Yup.string().required('Shipping Zip is required')
            yupShape.shippingCity = Yup.string().required('Shipping City is required')
        }

        if(potentialCustomer) {
            yupShape.email = Yup.string().required('Email is required');
        }

        return <div className="customer-create-form-container">
            <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    // this.setState({submitProcess: true})
                    createCustomerAction(values, potentialCustomer, history)
                }}
                validationSchema={
                    Yup.object().shape(yupShape)}
                render={({values, errors, status, touched, isSubmitting, handleChange, setFieldValue}) => (
                    <Form>
                        <FormSection title="Personal Information">
                            <FormElement label={t("Name")} required={true}>
                                <Field type="text" name="name"/>
                                {errors.name && touched.name && <div className="errors">{errors.name}</div>}
                            </FormElement>
                            <FormElement label={t("Email")} required={potentialCustomer ? true : false}>
                                <Field type="email" name="email"/>
                                {errors.email && touched.email && <div className="errors">{errors.email}</div>}
                            </FormElement>
                            <FormElement label={t("Vat")}>
                                <Field type="text" name="vat"/>
                                {errors.vat && touched.vat && <div className="errors">{errors.vat}</div>}
                            </FormElement>
                            {
                                activeUser.b2bView === 'CZ' &&
                                <FormElement label="IC">
                                    <Field type="text" name="ICCODE"/>
                                    {errors.ICCODE && touched.ICCODE && <div className="errors">{errors.ICCODE}</div>}
                                </FormElement>
                            }
                        </FormSection>
                        <FormSection title="Billing Information">
                            <FormElement label={t("Address")} required={true}>
                                <Field type="text" name="billingAddress"/>
                                {errors.billingAddress && touched.billingAddress &&
                                <div className="errors">{errors.billingAddress}</div>}
                            </FormElement>
                            <FormElement label={t("District")}>
                                <Field type="text" name="billingDistrict"/>
                                {errors.billingDistrict && touched.billingDistrict &&
                                <div className="errors">{errors.billingDistrict}</div>}
                            </FormElement>
                            <FormElement label={t("Zip")} required={true}>
                                <Field type="text" name="billingZip"/>
                                {errors.billingZip && touched.billingZip &&
                                <div className="errors">{errors.billingZip}</div>}
                            </FormElement>
                            <FormElement label={t("City")} required={true}>
                                <Field type="text" name="billingCity"/>
                                {errors.billingCity && touched.billingCity &&
                                <div className="errors">{errors.billingCity}</div>}
                            </FormElement>
                            <FormElement label={t("Phone")}>
                                <Field type="text" name="billingPhone"/>
                                {errors.billingPhone && touched.billingPhone &&
                                <div className="errors">{errors.billingPhone}</div>}
                            </FormElement>
                            <FormElement label={t("Country")}>
                                <Field component="select" name="billingCountry">
                                    {countries.map((country) => {
                                        return <option key={country.softoneId}
                                                       value={country.softoneId}>{country.name}</option>
                                    })}
                                </Field>
                            </FormElement>
                            <FormElement className="checkbox-element" label={t("Use a different shipping address")}>
                                <Field type="checkbox" name="useDifferentShippingAddress" onChange={(e) => {
                                    setFieldValue('useDifferentShippingAddress', e.target.checked);
                                    this.setState({
                                        showShipping: !this.state.showShipping,
                                    })
                                }}/>
                                {errors.useDifferentShippingAddress && touched.useDifferentShippingAddress &&
                                <div className="errors">{errors.useDifferentShippingAddress}</div>}
                            </FormElement>
                        </FormSection>

                        {this.state.showShipping ?
                            <FormSection title={t("Shipping Information")}>
                                <FormElement label={t("Address")}>
                                    <Field type="text" name="shippingAddress"/>
                                    {errors.shippingAddress && touched.shippingAddress &&
                                    <div className="errors">{errors.shippingAddress}</div>}
                                </FormElement>
                                <FormElement label={t("District")}>
                                    <Field type="text" name="shippingDistrict"/>
                                    {errors.shippingDistrict && touched.shippingDistrict &&
                                    <div className="errors">{errors.shippingDistrict}</div>}
                                </FormElement>
                                <FormElement label={t("Zip")}>
                                    <Field type="text" name="shippingZip"/>
                                    {errors.shippingZip && touched.shippingZip &&
                                    <div className="errors">{errors.shippingZip}</div>}
                                </FormElement>
                                <FormElement label={t("City")}>
                                    <Field type="text" name="shippingCity"/>
                                    {errors.shippingCity && touched.shippingCity &&
                                    <div className="errors">{errors.shippingCity}</div>}
                                </FormElement>
                                <FormElement label={t("Phone")}>
                                    <Field type="text" name="shippingPhone"/>
                                    {errors.shippingPhone && touched.shippingPhone &&
                                    <div className="errors">{errors.shippingPhone}</div>}
                                </FormElement>
                                <FormElement label={t("Country")}>
                                    <Field component="select" name="shippingCountry">
                                        {countries.map((country) => {
                                            return <option key={country.softoneId}
                                                           value={country.softoneId}>{country.name}</option>
                                        })}
                                    </Field>
                                    {errors.shippingCountry && touched.shippingCountry &&
                                    <div className="errors">{errors.shippingCountry}</div>}
                                </FormElement>
                            </FormSection>
                            : null}

                        {activeUser.allAccess ?
                            <FormElement label={t("Assign To Other Salesman")}>
                                <select
                                    name="alternateSalesman"
                                    value={values.alternateSalesman}
                                    onChange={handleChange}
                                    style={{display: 'block'}}
                                >
                                    {userSalesmen.map((salesman) => {
                                        return <option name="alternateSalesman" key={salesman.id}
                                                       value={salesman.id}>{salesman.name}</option>
                                    })}
                                </select>
                            </FormElement>
                            : null}

                        <button className="vamp-button" type="submit">
                            {potentialCustomer ? t('Create Potential') : t('Create Customer')}
                        </button>
                    </Form>
                )}
            />
            {customersWithTheSameAFM && Array.isArray(customersWithTheSameAFM) && customersWithTheSameAFM.length ? <SelectFromExistingCustomersPopup
                existingCustomers={customersWithTheSameAFM}
                clickAction={(id) => {
                    selectCustomerAction(id)
                    this.props.history.push('/app')
                }}
                closeAction={() => {
                    clearCustomerAfmExists()
                }}
            /> : null}
        </div>
    }
}

const mapStateToProps = ({customer, auth}) => {
    return {
        customer,
        auth
    }
}

export default withTranslation()(connect(mapStateToProps, {createCustomerAction, clearCustomerAfmExists, selectCustomerAction})(withRouter(CustomersCreateForm)))
