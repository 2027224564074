import React, {Component} from 'react'
import {connect} from 'react-redux'
import {BrowserRouter as Router, Route, Link} from "react-router-dom";

import {setDefaultAddress, updateEmail, deSelectActiveCustomer} from '../../actions/customerActions'
import { loadOrderAction, clearSavedOrder } from '../../actions/orderActions'

import AccountSidebar from "../AccountSidebar/AccountSidebar";
import AccountSidebarItem from "../AccountSidebar/AccountSidebarItem";
import OrdersPanel from "../Panels/OrdersPanel";
import CustomerStatements from "../Customer/Infos/CustomerStatements";
import CustomerShipments from "../Customer/Infos/CustomerShipments";
import DownloadsPage from "../Customer/DownloadsPage";
import CustomerInvoices from "../Customer/Infos/CustomerInvoices";
import AddressesPanel from "../Panels/AddressesPanel";
import AccountPanel from "../Panels/AccountPanel";
import UserOrdersPanel from "../Panels/UserOrdersPanel";
import OrderDetails from "../Panels/OrderDetails";
import Api from '../../controllers/Api'

import {ReactComponent as PinSvg} from "../../components/assets/pin.svg";
import {ReactComponent as ShipmentSvg} from "../../components/assets/shippment.svg";
import {ReactComponent as Statement2Svg} from "../../components/assets/statement_2.svg";
import {ReactComponent as StatementSvg} from "../../components/assets/statement.svg";
import {ReactComponent as OrderSvg} from "../../components/assets/order-1.svg";
import {ReactComponent as InvoiceSvg} from "../../components/assets/invoice.svg";
import {ReactComponent as DownloadSvg} from "../../components/assets/download.svg";
import {ReactComponent as CustomerSvg} from "../../components/assets/customer.svg";
import {ReactComponent as LogoutSvg} from "../../components/assets/logout.svg";
import {ReactComponent as InvoicesNewSvg} from "../../components/assets/customer_balances_new.svg";
import { withTranslation } from 'react-i18next';

import CustomerCredits from "../Customer/Infos/CustomerCredits";


class CustomerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloadLinks: ''
        }
    }

    componentDidMount() {
        Api.settings.getDownloadLinks().then(res => {
            this.setState({
                downloadLinks: res.downloadLinks,
            })
        })

        if( this.props.history.location.search === '?new_customer=true' ){
            alert("You have created a new user successfully!")
        }
    }

    render() {
        const {
            customer: {activeCustomer},
            setDefaultAddress,
            activeUser,
            updateEmail,
            deSelectActiveCustomer,
            history,
            loadOrderAction,
            clearSavedOrder,
        } = this.props
        const { t } = this.props;

        const hidePrices = localStorage.getItem('vamp_app_hide_prices')
        const canEdit = (activeUser && activeUser.role !== 'customer')

        if(!activeCustomer) {
            history.push('/app')
            return null
        }

        return <div className="account-page">
            <div className="account-sidebar">
                <AccountSidebar>
                    <AccountSidebarItem
                        title={t("Account")}
                        link="/app/customer/account"
                        icon={<CustomerSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Orders")}
                        link="/app/customer/orders"
                        icon={<InvoiceSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Saved Orders")}
                        link="/app/customer/orders/saved"
                        icon={<OrderSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Addresses")}
                        link="/app/customer/addresses"
                        icon={<PinSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Downloads")}
                        link="/app/customer/downloads"
                        icon={<DownloadSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Statements")}
                        link="/app/customer/statements"
                        icon={<Statement2Svg/>}
                    />
                    <AccountSidebarItem
                        title={t("Shipments")}
                        link="/app/customer/shipments"
                        icon={<ShipmentSvg/>}
                    />
                    <AccountSidebarItem
                        title={t("Invoices & Credit Notes")}
                        link="/app/customer-information"
                        icon={<InvoicesNewSvg/>}
                    />
                    {canEdit ? <AccountSidebarItem
                        title={t("Logout Customer")}
                        icon={<LogoutSvg/>}
                        handleClick={() => deSelectActiveCustomer()}
                    /> : null}
                </AccountSidebar>
            </div>
            <div className="panel account-panel">
                <Route path="/app/customer/account" exact
                       component={() => <AccountPanel
                           user={activeCustomer}
                           hidePrices={hidePrices}
                           editAccess={canEdit}
                           handleEdit={(email) => updateEmail(activeCustomer._id, email)}
                       />
                       }/>
                <Route path="/app/customer/statements" exact component={(props) => <CustomerStatements {...props}
                    user={activeCustomer}
                />}/>
                <Route path="/app/customer/shipments" exact component={(props) => <CustomerShipments {...props}
                                                                                                       user={activeCustomer}
                />}/>

                <Route path="/app/customer/orders/details/:id" exact component={(props) => <OrderDetails {...props} />}/>
                <Route path="/app/customer/orders" exact component={(props) => <OrdersPanel {...props} />}/>
                <Route path="/app/customer/downloads" exact component={(props) => <DownloadsPage links={this.state.downloadLinks} {...props} />}/>
                <Route path="/app/customer/orders/saved" exact component={() =>
                    <UserOrdersPanel
                        clearSavedOrder={clearSavedOrder}
                        loadOrderAction={(orderId) => {
                            loadOrderAction(orderId)
                            history.push('/app')
                        }
                        }
                        user={activeCustomer}
                    />}
                />
                <Route path="/app/customer/addresses" exact
                       component={() => <AddressesPanel setDefaultAddress={(addressId, customerId) => {
                           setDefaultAddress(addressId, customerId)
                       }} activeCustomer={activeCustomer}/>}/>
            </div>
        </div>
    }
}

const mapStateToProps = ({customer, auth}) => {
    return {
        customer,
        activeUser: auth.activeUser
    }
}

export default withTranslation()(connect(mapStateToProps, {
    setDefaultAddress,
    updateEmail,
    deSelectActiveCustomer,
    loadOrderAction,
    clearSavedOrder
})(CustomerPage))
