import React, {useState, useEffect} from 'react'
import Button from "../Button";
import {ReactComponent as CloseSvg} from "../assets/close.svg";
import {ReactComponent as ClearSvg} from "../assets/bin.svg";
import {Range} from "react-range";
import {camelize} from "../../utils/utils";
import {connect} from "react-redux";
import { useTranslation } from "react-i18next";

import './FiltersComponent.scss'

const FiltersComponent = (
    {
        toggleFiltersShow,
        showFilters,
        clearFilters,
        minPrice,
        maxPrice,
        priceFilterValue,
        handlePriceFilterDebounced,
        handlePriceFilter,
        setPriceFilterValue,
        currencySymbol,
        toggleFilter,
        filters,
        activeFilters,
        activeUser
    }
) => {
    const { t } = useTranslation();

    //let [tempFilters, setTempFilters] = useState([])

    let activeFiltersCount = 0

    if(!filters) {
        filters = {}
    }

    if (activeFilters) {
        Object.values(activeFilters).filter(set => {
            activeFiltersCount += set.size
        })
    }

    /*const tempFilterValues = Object.values(filters).filter( ( value => { return !( ( activeUser.b2bView === 'GR' && value.key === 'campaignCZ' ) || ( activeUser.b2bView === 'CZ' && value.key === 'campaignGR' ) ) } ) ).map( (value) => {
        value.open = false;
        return value
    } );*/

    useEffect(() => {
    }, [])

    const changeFilter = (selectedValue) => {
        const selected = 'content_' + selectedValue
        if( document.getElementById(selected).style.display === 'none' || ( document.getElementById(selected).className === 'filter-values' && document.getElementById(selected).style.display !== 'block' ) )
            document.getElementById(selected).style.display = 'block'
        else
            document.getElementById(selected).style.display = 'none'
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('factory');

    return <div className="filters">
        {showFilters ?
            <div className="filters-overlay" onClick={() => {
                toggleFiltersShow(false)
            }}></div>
            : null}
        { myParam !== 'Simone' && <div className="filters-toggle-button">
            <Button title={`${t('Filters')}${activeFiltersCount ? ` (${activeFiltersCount})` : ''}`}
                    callback={() => toggleFiltersShow(!showFilters)}/>
        </div> }
        <div className={`filters-list-container ${showFilters ? 'show-filters' : ''}`}>
            <div className="filter-actions">
                <Button className="vamp-button-blue"
                        callback={() => toggleFiltersShow(false)}>
                    <CloseSvg/>
                </Button>
                <Button callback={() => clearFilters()}>
                    <ClearSvg/>
                </Button>
            </div>
            <div className="filters-list-inner">
                <ul className="filters-list">
                    {/*Price filters*/}
                    {/*{*/}
                    {/*    (*/}
                    {/*        (minPrice && maxPrice) &&*/}
                    {/*        (minPrice < maxPrice) &&*/}
                    {/*        priceFilterValue &&*/}
                    {/*        priceFilterValue.length &&*/}
                    {/*        !priceFilterValue.includes(undefined) &&*/}
                    {/*        parseFloat(priceFilterValue[1]) <= parseFloat(maxPrice)*/}
                    {/*    ) ?*/}
                    {/*        <li className="filter-price">*/}
                    {/*            <Range*/}
                    {/*                step={1}*/}
                    {/*                min={minPrice}*/}
                    {/*                max={maxPrice}*/}
                    {/*                values={priceFilterValue}*/}
                    {/*                onChange={values => {*/}
                    {/*                    handlePriceFilterDebounced(values, handlePriceFilter)*/}
                    {/*                    setPriceFilterValue(values)*/}
                    {/*                }}*/}
                    {/*                renderTrack={({props, children}) => (*/}
                    {/*                    <div*/}
                    {/*                        {...props}*/}
                    {/*                        style={{*/}
                    {/*                            ...props.style,*/}
                    {/*                            height: '6px',*/}
                    {/*                            width: '100%',*/}
                    {/*                            backgroundColor: '#ccc'*/}
                    {/*                        }}*/}
                    {/*                    >*/}
                    {/*                        {children}*/}
                    {/*                    </div>*/}
                    {/*                )}*/}
                    {/*                renderThumb={({props}) => (*/}
                    {/*                    <div*/}
                    {/*                        {...props}*/}
                    {/*                        style={{*/}
                    {/*                            ...props.style,*/}
                    {/*                            height: '25px',*/}
                    {/*                            width: '25px',*/}
                    {/*                            borderRadius: '50%',*/}
                    {/*                            backgroundColor: '#000'*/}
                    {/*                        }}*/}
                    {/*                    />*/}
                    {/*                )}*/}
                    {/*            />*/}
                    {/*            <div className="price-range-values">*/}
                    {/*                {`${priceFilterValue[0].toFixed(2)}${currencySymbol} - ${priceFilterValue[1].toFixed(2)}${currencySymbol}`}*/}
                    {/*            </div>*/}
                    {/*        </li>*/}
                    {/*        : null}*/}
                    {Object.values(filters).filter( ( value => { return !( ( activeUser.b2bView === 'GR' && value.key === 'campaignCZ' ) || ( activeUser.b2bView === 'CZ' && value.key === 'campaignGR' ) ) } ) ).map((filter, index) => {
                        let filterItems = Array.from(filter.values).sort((a, b) => {
                            return a - b
                        })

                        return <li key={filter.key}>
                            <div onClick={() => changeFilter(filter.key)} id={`id_${filter.key}`} className='filter-label'>
                                <span>{t(filter.label)}</span>
                            </div>
                            <div id={`content_${filter.key}`} className={  ( filter.key === 'arttype' || filter.key === 'gender' || filter.key === 'sizeCode' ) ? '' : 'filter-values'}>
                                { //filter.open &&
                                    <ul className="filter-values-list">
                                        {filterItems.map((value, index) => {
                                            let filterActive = false

                                            if (activeFilters && activeFilters[filter.key] && activeFilters[filter.key].has(value)) {
                                                filterActive = true;
                                            }

                                            return <li
                                                key={index}
                                                className={`${filterActive ? 'active' : ''} ${filter.key}`}
                                                onClick={() => toggleFilter(filter.key, value)}>{value === true ? 'Yes' : ( value === false ? 'No' : t(camelize(value)) ) }
                                            </li>
                                        })}
                                    </ul>
                                }
                            </div>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    </div>
}

const mapStateToProps = ({ auth }) => {
    return {
        activeUser: auth.activeUser,
    }
}

export default connect(mapStateToProps, null)(FiltersComponent)