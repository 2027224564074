import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MultiGrid from "../../MultiGrid/MultiGrid";
import ProductImages from "../../Product/ProductImages";
import { useTranslation } from "react-i18next";

import {getCurrencySymbol, getLivePrices} from "../../../utils/priceUtils";
import {addToCartAction, updateCartAction} from "../../../actions/cartActions";
import {setLastVisitedProduct} from "../../../actions/productAction";
import { printDeliveryWeek } from '../../../utils/productUtils'
import { getProductPrices, printProductPrice } from '../../../utils/priceUtils'

import './GridMode.scss'

const printProductMeta = (product) => {
    return <Fragment>
        {(product.length || product.front || product.pants) ? <div className="product-attributes">
            {product.length ? <span>{product.length}cm</span> : null}
            {product.front ? <span>{product.front}</span> : null}
            {product.pants ? <span>{product.pants}</span> : null}
        </div> : null}
    </Fragment>
}

const printProductAvailability = (product) => {
    if(!product.variations || !product.variations.length) {
        return null
    }

    if(product.variations[0].storeView !== 'gr') {
        return null
    }

    //Find larger availability
    let stock = 0
    let firstTier = 0
    let secondTier = 0

    product.variations.filter(variation => {
        stock += parseInt(variation.stocks.gr.qty)

        if(variation.stocks.gr.pending.firstTier) {
            firstTier += parseInt(variation.stocks.gr.pending.firstTier)
        }

        if(variation.stocks.gr.pending.secondTier) {
            secondTier += parseInt(variation.stocks.gr.pending.secondTier)
        }
    })

    let availability = 'unavailable'

    if(stock) {
        availability = 'now'
    }else if(firstTier) {
        availability = '5days'
    }else if(secondTier) {
        availability = '30days'
    }

    switch (availability) {
        case "30days": {
            return <div className="product-availability second-tier">Available in 30 days</div>
            break
        }
        case "5days": {
            return <div className="product-availability first-tier">Available in 5 days</div>
            break
        }
        case "now": {
            return <div className="product-availability available">Available</div>
            break
        }
        default :{
            return <div className="product-availability unavailable">Unavailable</div>
        }
    }
}

const GridMode = (
    { product, customer: { activeCustomer },
        addToCartAction,
        setLastVisitedProduct,
        cart: { cartItems } }
) => {
    const { t } = useTranslation();

    const [ livePricesArr, setLivePricesArr ] = useState([])
    const [ selectedColor, setSelectedColor ] = useState(false)

    let customer = activeCustomer

    if(!customer) {
        customer = {}
    }

    let productImages = product.additionalImages.slice()
    productImages.unshift(product.image)

    useEffect(() => {
        if(customer && customer._id) {
            getLivePrices([product], customer, false).then(livePrices => {
                setLivePricesArr(livePrices)
            })
        }
    }, [ customer ])

    useEffect(() => {
        setLastVisitedProduct(product._id)
    }, [])

    const {
        price,
        regularPrice,
        livePriceMatched,
    } = getProductPrices(product, livePricesArr)

    const currency = getCurrencySymbol(activeCustomer)

    const hidePrices = localStorage.getItem('vamp_app_hide_prices')

    productImages = productImages.slice().map(image => {
        return {
            url: image,
            type: 'image',
            visible: true
        }
    })

    if(selectedColor){
        productImages = productImages.map( image => {
            let visibility = false
            for( let x = 0 ; x < product.additionalImagesWithAtt.length ; x++ ){
                if( product.additionalImagesWithAtt[x].colorcode === selectedColor && image.url.includes(product.additionalImagesWithAtt[x].filename) ){
                    visibility = true
                }
            }
            image.visible = visibility
            return image
        } )

    }

    if( productImages.filter( tempProduct => tempProduct.visible === true ).length === 0 && selectedColor ){
        productImages = productImages.map( image => {
            image.visible = true
            return image
        })
    }

    console.log(product)

    return <div className="product-grid-mode">
        <div className="grid-container">
            <div className="left-container">
                <div className="product-images">
                    <ProductImages
                        images={productImages}
                        videos={product.videos}
                        infinite={false}
                        slidesPerPage={1}
                    />
                </div>
            </div>
            <div className="right-container">
                <header className="product-header">
                    <div className="left-container">
                        <div className="product-link">
                            <div className="sku">
                                <span className="label">{t('Art')}.: </span>
                                <span className="value">{product.code}</span>
                            </div>
                        </div>
                        <div className="title-container">
                            <div className="title">{product.titleEN}</div>
                            {product.fabric ?
                                <div className="product-fabric">
                                    {product.fabric}
                                </div>
                                : null}
                        </div>
                        {printProductMeta(product)}
                        {/*{printProductAvailability(product)}*/}
                    </div>
                    {
                        product.factory === 'Simone' && <div className='simone-logo-container-product-page'>
                            <img src='/simoneLogo.svg'/>
                        </div>

                    }

                    <div className="right-container">
                        {!hidePrices ?
                            <div className="product-price-container">
                                {printProductPrice(price, regularPrice, livePriceMatched, currency, product)}
                            </div>
                            : null}
                        {printDeliveryWeek(product)}
                    </div>
                </header>
                <MultiGrid
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                    cartItems={cartItems}
                    currency={currency}
                    product={product}
                    livePrices={livePricesArr}
                    extended={true}
                    addToCartAction={(payload) => {
                        addToCartAction(payload)
                    }}
                />
            </div>
        </div>
    </div>
}

const mapStateToProps = ({ cart, customer }) => {
    return {
        cart,
        customer
    }
}

export default connect(mapStateToProps, {addToCartAction, updateCartAction, setLastVisitedProduct})(GridMode)
