import React from 'react'
import {connect} from 'react-redux'
import {getCurrencySymbol, getCustomerPriceZone} from "../../utils/priceUtils";

import {wishlistToggleAction} from '../../actions/wishlistActions'

import FamilyHeader from "./FamilyHeader";
import ProductsList from '../Product/ProductsList'

import './FamilyPage.scss'
import ArchivePage from "../Pages/Archive/ArchivePage";
import Loader from "../Common/Loader";

const getPrevNextFamilies = (seasonFamilies, familyId) => {
    //Find next and prev family
    let prevFamilyId = false
    let nextFamilyId = false

    console.log('here')

    for(let i = 0; i < seasonFamilies.length; i++) {
        if(seasonFamilies[i]._id === familyId) {
            const nextIndex = i + 1
            const prevIndex = i - 1

            if(nextIndex < seasonFamilies.length) {
                nextFamilyId = seasonFamilies[i + 1]._id
            }

            if(prevIndex >= 0) {
                prevFamilyId = seasonFamilies[i - 1]._id
            }

            break
        }
    }

    return {
        prevFamilyId,
        nextFamilyId,
    }
}

const FamilyPage = (
    {
        match,
        products,
        seasons,
        history,
        wishlistItems,
        wishlistToggleAction,
        variationPrices,
        activeCustomer,
        defaultSeason,
        applyBannerFamilies,
        seasonsTemp
    }) => {

    const {params: {familyId}} = match

    let season = false;
    let family = false
    let seasonFamilies = []

    const urlParams = new URLSearchParams(window.location.search);
    const factoryParam = urlParams.get('factory');
    const isSimonePreorder = factoryParam === 'Simone';

    for (let i = 0; i < seasons.length; i++) {
        let seasonFound = false

        for (let j = 0; j < seasons[i].families.length; j++) {
            if (seasons[i].families[j]._id === familyId) {
                seasonFound = true;
                season = Object.assign({}, seasons[i])
                family = Object.assign({}, seasons[i].families[j])
                seasonFamilies = seasons[i].families
                break;
            }
        }

        if (seasonFound) {
            break
        }
    }

    seasonFamilies = isSimonePreorder ? seasonFamilies.filter( tempSeason => tempSeason.factory === 'Simone'  ) : seasonFamilies.filter( tempSeason => tempSeason.factory !== 'Simone'  )

    let colors = {}
    let sizes = new Set()

    if(!family || !family._id) {
        return <Loader />
    }

    const { prevFamilyId, nextFamilyId } = getPrevNextFamilies(seasonFamilies, family._id)

    return <div className="family-archive-page">
        <FamilyHeader
            history={history}
            season={season}
            defaultSeason={defaultSeason}
            title={family.friendlyName || family.name}
            colors={Object.values(colors)}
            composition={family.composition || ''}
            fabric={family.fabric || ''}
            sizes={Array.from(sizes)}
            bannerDesktop={family.bannerDesktop || ''}
            bannerMobile={family.bannerMobile || ''}
            applyBanner={applyBannerFamilies.includes(family.softoneCode)}
        />
        <ArchivePage
            history={history}
            preorder={true}
            familyId={family._id}
            prevFamily={() => {
                if(prevFamilyId) {
                    history.push(`/app/presentation/family/${prevFamilyId}${ isSimonePreorder ? '?factory=Simone' : '' }`)
                }
                // window.location.reload()
            }}
            nextFamily={() => {
                if(nextFamilyId) {
                    history.push(`/app/presentation/family/${nextFamilyId}${ isSimonePreorder ? '?factory=Simone' : '' }`)
                }
                // window.location.reload()
            }}
        />
    </div>
}

const mapStateToProps = ({product, season, wishlist, customer, settings}) => {
    return {
        products: product.products,
        variationPrices: product.variationPrices,
        seasons: season.seasons,
        seasonsTemp: season,
        wishlistItems: wishlist.items,
        activeCustomer: customer.activeCustomer,
        defaultSeason: settings.settings.presentation.defaultSeason,
        applyBannerFamilies: settings.settings.presentationBannersAllowed
    }
}

export default connect(mapStateToProps, {wishlistToggleAction})(FamilyPage)
