import React from 'react'
import {ReactComponent as BackSvg} from '../../assets/back.svg';

import './SeasonArchiveHeader.scss'
import ProductsArchiveHeader from "../../Common/Archive/ProductsArchiveHeader";
import { useTranslation } from "react-i18next";

const SeasonArchiveHeader = ({
                                 backAction,
                                 backLabel,
                                 switchViewAction,
                                 activeView,
                                 salesmanTopProducts,
                                 customerTopProducts,
                                 topProductsLink,
                                 topProductsLinkSalesman,
                                 title,
                             }) => {

    const urlParams = new URLSearchParams(window.location.search);
    const factoryParam = urlParams.get('factory');
    const isSimonePreorder = factoryParam === 'Simone';

    const { t } = useTranslation();
    return <header className="season-archive-header">
        <ProductsArchiveHeader title={title ? title : 'Families'}/>
        <div className="header-extras">
            <div className="left-container">
                <button className="back-button" onClick={backAction}><BackSvg/><span>{backLabel}</span></button>
            </div>
            {
                isSimonePreorder ? '' : <div className="right-container">
                    <button className="action-button" onClick={switchViewAction}>{activeView}</button>
                </div>
            }

        </div>
        {
            isSimonePreorder ? '' : <div className="top-products-control">
                {salesmanTopProducts ? <button className="action-button top-products-link"
                                               onClick={() => topProductsLinkSalesman()}>{t('Top Articles ( Agent )')}</button> : null}
                {customerTopProducts ?
                    <button className="action-button top-products-link"
                            onClick={() => topProductsLink()}>{t('Top Articles')}</button> : null}
            </div>
        }
    </header>
}

export default SeasonArchiveHeader
