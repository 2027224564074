import { TYPES } from '../actions/productAction'
import {TYPES as authTypes} from '../actions/authActions'

const initialState = {
    products: [],
    samples: [],
    posters: [],
    variationPrices: [],
    pricesSet: false
};

const product = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.CLEAR_PRODUCT_SET_PRICES: {
            return {
                ...state,
                pricesSet: initialState.pricesSet
            }
        }
        case TYPES.FETCHED_SAMPLES: {
            return {
                ...state,
                samples: action.payload
            }
        }
        case TYPES.GET_IF_NEW_PRODUCT: {
            return {
                ...state,
                isNewProductExist: action.payload
            }
        }
        case TYPES.CLEAR_LIVE_PRICES: {
            return {
                ...state,
                variationPrices: initialState.variationPrices
            }
        }
        case TYPES.GET_POSTERS: {
            const posters = action.payload
            // posters.sort((a, b) => (a.code.replace(/\D/g,'') > b.code.replace(/\D/g,'')) ? 1 : -1)

            return {
                ...state,
                posters
            }
        }
        case TYPES.FETCH_PRODUCTS_SUCCESS: {
            return {
                ...state,
                products: action.payload,
            }
        }
        case TYPES.SET_PRODUCT_PRICES: {
            const { products } = state
            const activeCustomer = action.payload

            let activeCurrency = 'EURO'
            let b2bView = 'GR'
            let customerPriceZone = 'PRICEW'

            if(activeCustomer) {
                activeCurrency = activeCustomer.currency
                b2bView = activeCustomer.b2bView
                customerPriceZone = activeCustomer.customer[0].priceZone
            }

            const productsArray = products.map((product) => {
                if(product.variations && product.variations.length) {
                    product.variations = product.variations.map((productVariation) => {
                        if(productVariation.prices && productVariation.prices.length) {
                            const prices = productVariation.prices

                            for(let i = 0; i < prices.length; i++) {
                                if(b2bView === 'GR') {
                                    if(prices[i].priceZone === customerPriceZone) {
                                        productVariation.price = prices[i].price
                                        break;
                                    }
                                }else {
                                    if(prices[i].currency === activeCurrency && prices[i].country === b2bView) {
                                        productVariation.price = prices[i].price
                                        break;
                                    }
                                }
                            }
                        }
                        return productVariation
                    })
                }
                return product
            })

            return {
                ...state,
                products: productsArray,
                pricesSet: true
            }
        }
        case TYPES.FETCHED_LIVE_PRICES: {
            const livePrices = action.payload
            let variationPrices = state.variationPrices

            if(!variationPrices.length) {
                return {
                    ...state,
                    variationPrices: livePrices
                }
            }

            //Search for the price
            for(let i = 0; i < livePrices.length; i++) {
                let priceExists = false

                for(let j = 0; j < variationPrices.length; j++) {
                    if(livePrices[i].barcode === variationPrices[j].barcode) {
                        priceExists = true;
                        break
                    }
                }

                if(!priceExists) {
                    variationPrices.push(livePrices[i])
                }
            }

            return {
                ...state,
                variationPrices
            }
        }
        case authTypes.LOGOUT_ACTION: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state
    }
};


export default product
