import React, { Fragment } from 'react';
import ReactTooltip from "react-tooltip";
import {ReactComponent as PlusSvg} from '../assets/plus.svg';
import {ReactComponent as MinusSvg} from '../assets/minus.svg';
import {isTablet} from "../../utils/utils";

import './MultiGridRow.scss'

const printSizeCells = (colorId, sizes, handleInputChange, setFocusedInput, focusedInputBarcode) => {
    return sizes.map((size) => {

        //const availabilityColor = calculateAvailabilityColor(size)
        const availabilityColor = ''

        return <td key={size.barcode} className="size-cell-container">
            <input
                data-tip
                data-for={`tooltip-${size.barcode}`}
                data-barcode={size.barcode}
                min={0}
                max={size.stock}
                disabled={size.stock <= 0}
                type="number"
                value={size.inputValue}
                className={`${(focusedInputBarcode && (focusedInputBarcode === size.barcode)) ? 'input-focused' : ''} ${availabilityColor}`}
                onFocus={(e) => {
                    e.target.select()

                    if (isTablet()) {
                        e.target.blur()
                    }
                    setFocusedInput(size.barcode)
                }}
                onChange={(e) => handleInputChange(e, size.barcode, colorId)}
            />
            <div className="size-stock">
                {printAvailableStockInfo(size)}
            </div>
            {/*<ReactTooltip id={`tooltip-${size.barcode}`}>*/}
            {/*    {printStockInfo(size)}*/}
            {/*</ReactTooltip>*/}
        </td>
    })
}

const calculateAvailabilityColor = (size) => {
    //Find larger availability
    let stock = 0
    let firstTier = 0
    let secondTier = 0

    stock += parseInt(size.stocks.gr.qty) - parseInt(size.stocks.gr.pending.firstTier) - parseInt(size.stocks.gr.pending.secondTier)
    firstTier += parseInt(size.stocks.gr.pending.firstTier)
    secondTier += parseInt(size.stocks.gr.pending.secondTier)

    let availability = 'availability-unavailable'

    if(stock) {
        availability = 'availability-now'
    }else if(firstTier) {
        availability = 'availability-5days'
    }else if(secondTier) {
        availability = 'availability-30days'
    }

    return availability
}

const printAvailableStockInfo = (size) => {
    const stock = size.stock
    //const storeView = size.storeView

    if (stock === 999) {
        return null
    }

    //const availableNow = (stock - size.stocks[storeView].pending.firstTier - size.stocks[storeView].pending.secondTier)

    let stockToDisplay = stock

    if(stockToDisplay > 10) {
        stockToDisplay = 10
    }

    return <div className="stock-info">
        <p>Stock: {stockToDisplay}</p>
    </div>
}

const printStockInfo = (size) => {
    const stock = size.stock
    const storeView = size.storeView

    if (stock === 999) {
        return null
    }

    const availableNow = (stock - size.stocks[storeView].pending.firstTier - size.stocks[storeView].pending.secondTier)

    return <div className="stock-info">
        {(storeView && size.stocks[storeView] && size.stocks[storeView].pending)
            ?
            <Fragment>
                <div className="variation-stock-tooltip">
                    {availableNow ?
                        <p>Available now: {availableNow}</p>
                        :
                    null
                    }
                    {size.stocks[storeView].pending.firstTier ?
                        <p>Available in 5 days: {size.stocks[storeView].pending.firstTier}</p>
                        :
                    null
                    }
                    {size.stocks[storeView].pending.secondTier ?
                        <p>Available in 30 days: {size.stocks[storeView].pending.secondTier}</p>
                        :
                    null
                    }
                </div>
            </Fragment>
            :
            null
        }
    </div>
}

const findVariationPrice = (variationPrices, size) => {

    for (let i = 0; i < variationPrices.length; i++) {
        if (variationPrices[i].barcode === size.barcode) {
            return variationPrices[i].price
        }
    }

    return size.price
}

const printRowTotals = (item, currencySymbol, hidePrices) => {

    if(hidePrices) {
        return null;
    }

    let totalQty = 0
    let totalPrice = 0

    for (let i = 0; i < item.sizes.length; i++) {
        totalQty += parseInt(item.sizes[i].inputValue);
        totalPrice += parseFloat(item.sizes[i].price) * parseInt(item.sizes[i].inputValue);
    }

    if (!totalQty && !totalPrice) {
        return null
    }

    totalPrice = totalPrice.toFixed(2)

    return <div className="line-totals-container">
        <div className="line-stock">{totalQty}</div>
        <div className="line-price">{currencySymbol}{totalPrice}</div>
    </div>
}

const MultiGridRow = ({item, alterRow, handleInputChange, currency, setFocusedInput, focusedInputBarcode, hidePrices, selectedColor, setSelectedColor}) => {

    console.log(item)

    if (item.sizes && item.sizes.length) {
        const colorBoxStyle = {
            background: `${item.colorHex}`,
        };

        return <tr className="multigrid-row">
            <td onClick={ () => selectedColor === item.colorCode ? setSelectedColor(false) : setSelectedColor(item.colorCode) }  className={  ( selectedColor === item.colorCode && selectedColor ) ? 'color-cell-container selectedColor-cell' : 'color-cell-container' } >
                <div className="color-hex" style={colorBoxStyle}>
                </div>
                <div className="color-name-box">
                    <span className="color-code">{item.colorCode}</span>
                    <span className="color-code">{item.colorName.toLowerCase()}</span>
                </div>
            </td>
            {printSizeCells(item.colorId, item.sizes, handleInputChange, setFocusedInput, focusedInputBarcode)}
            <td className="line-totals">
                {printRowTotals(item, currency, hidePrices)}
            </td>
            <td className="row-controls">
            <span onClick={() => alterRow(item.colorId, 'plus')}>
                <PlusSvg/>
            </span>
                <span onClick={() => alterRow(item.colorId, 'minus')}>
                <MinusSvg/>
            </span>
            </td>
        </tr>
    } else {
        return <tr className="multigrid-row">
            <td key={item.code} className="size-cell-container">
                <input
                    min={0}
                    type="number"
                    value={item.inputValue}
                    onChange={(e) => handleInputChange(e, item.code)}
                    onFocus={(e) => e.target.select()}
                />
            </td>
            <td className="row-controls">
            <span onClick={() => alterRow(item.colorId, 'plus')}>
                <PlusSvg/>
            </span>
                <span onClick={() => alterRow(item.colorId, 'minus')}>
                <MinusSvg/>
            </span>
            </td>
        </tr>
    }
}

export default MultiGridRow
