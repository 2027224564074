import React, {useState} from "react";

import Api from '../../controllers/Api'

import './ResetPasswordForm.scss'
import { useTranslation } from "react-i18next";

const ResetPasswordForm = () => {
    const { t } = useTranslation();

    const [ showResetPassForm, setShowResetPassForm ] = useState(false)
    const [ emailValue, setEmailValue ] = useState('')
    const [ requestResponse, setRequestResponse ] = useState('')

    return <div className="return-password-form">
        <header>
            <h4 onClick={() => setShowResetPassForm(!showResetPassForm)}>
                <span className="entry-message-underline">{t('Forgotten Password')}</span>
            </h4>
        </header>
        {showResetPassForm ?
            <form onSubmit={(e) => {
                e.preventDefault();
                setRequestResponse('')

                Api.auth.requestPasswordReset(emailValue)
                    .then(response => {
                        alert(t('Thanks for submitting your email address'))
                    })
                    .catch(err => {
                        if(err && err.response.data && err.response.data.message) {
                            switch (err.response.data.message){
                                case 'missing_user': {
                                    setRequestResponse(t('E-mail not Registered Please contact your agent'))
                                    break
                                }
                                case 'max_attempts': {
                                    setRequestResponse(t('You have reached the request limit, please contact us'))
                                    break
                                }
                            }

                            return;
                        }

                        setRequestResponse('General Error, please contact us')
                    });
            }
            }>
                <p>
                    {t('Please enter your email address and click SEND')}
                </p>
                <div className="form-group">
                    <label>
                        Email
                    </label>
                    <input placeholder="email address..." required={true} type="email" value={emailValue} onChange={(e) => setEmailValue(e.target.value)}/>
                    {requestResponse ? <span className="err-message">{requestResponse}</span> : null}
                </div>
                <div className="form-group">
                    <button>
                        {t('Send')}</button>
                </div>
            </form>
            : null}
    </div>
}

export default ResetPasswordForm