import Api from "../controllers/Api";
import {getLivePrices, clearLivePrices, setProductPrices, clearSetPrices} from "./productAction";
import {getWishlistItems, clearWishlist} from "./wishlistActions";
import {clearCartAction, clearCartActionInState, getCartAction, getSampleCartAction} from './cartActions'
import { setSelectedCustomer } from "./authActions";

export const TYPES = {
    FETCH_CUSTOMERS_SUCCESS: 'FETCH_CUSTOMERS_SUCCESS',
    SELECT_CUSTOMER: 'SELECT_CUSTOMER',
    LOGOUT_CUSTOMER: 'LOGOUT_CUSTOMER',
    SELECT_CUSTOMER_FROM_SESSION: 'SELECT_CUSTOMER_FROM_SESSION',
    GET_COUNTRIES: 'GET_COUNTRIES',
    CREATED_CUSTOMER: 'CREATED_CUSTOMER',
    FETCH_CUSTOMER_ORDERS: 'FETCH_CUSTOMER_ORDERS',
    CLEAR_CUSTOMER_ORDERS: 'CLEAR_CUSTOMER_ORDERS',
    CUSTOMER_AFM_EXISTS: 'CUSTOMER_AFM_EXISTS',
    CLEAR_CUSTOMER_AFM_EXISTS: 'CLEAR_CUSTOMER_AFM_EXISTS',
    DEFAULT_ADDRESS_SET: 'DEFAULT_ADDRESS_SET',
    UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
    GRAND_ACCESS: 'GRAND_ACCESS',
    UPDATE_CUSTOMER_SEASON: 'UPDATE_CUSTOMER_SEASON',
    UPDATE_CUSTOMER_SEASON_FAILED: 'UPDATE_CUSTOMER_SEASON_FAILED',
    CREATE_CUSTOMER_ACCESS_ACTION: 'CREATE_CUSTOMER_ACCESS_ACTION',
    CREATE_CUSTOMER_ACCESS_ACTION_FAILED: 'CREATE_CUSTOMER_ACCESS_ACTION_FAILED',
    REVOKE_CUSTOMER_ACCESS_ACTION: 'REVOKE_CUSTOMER_ACCESS_ACTION',
    REVOKE_CUSTOMER_ACCESS_ACTION_FAILED: 'REVOKE_CUSTOMER_ACCESS_ACTION_FAILED',
    UPDATE_EMAIL: 'UPDATE_EMAIL',
    UPDATE_EMAIL_FAILED: 'UPDATE_EMAIL_FAILED',
    UPDATE_MOBILE: 'UPDATE_MOBILE',
    UPDATE_MOBILE_FAILED: 'UPDATE_MOBILE_FAILED',
    UPDATE_SINGLE_CUSTOMER: 'UPDATE_SINGLE_CUSTOMER',
    CUSTOMER_FORCE_ACCESS_GRANTED: 'CUSTOMER_FORCE_ACCESS_GRANTED',
    CUSTOMER_FORCE_ACCESS_FAILED: 'CUSTOMER_FORCE_ACCESS_FAILED',
}

export const updateEmail = (customerId, email) => {
    return async (dispatch, getState) => {
        try {
            const customer = await Api.customer.updateEmail(customerId, email)

            alert("Updated user email successful")


            dispatch({
                type: TYPES.UPDATE_EMAIL,
                payload: customer
            })
        } catch (e) {

            if( email ){
                alert("This email is alredy in use!")
            }
            else{
                alert("Email can't be empty")
            }


            dispatch({
                type: TYPES.UPDATE_EMAIL_FAILED,
            })
        }
    }
}

export const updateMobile = (customerId, mobile) => {
    return async (dispatch, getState) => {
        try {
            const customer = await Api.customer.updateMobile(customerId, mobile)

            alert("Updated user mobile successful")


            dispatch({
                type: TYPES.UPDATE_EMAIL,
                payload: customer
            })
        } catch (e) {

            alert("Update user mobile failed")

            dispatch({
                type: TYPES.UPDATE_EMAIL_FAILED,
            })
        }
    }
}

export const updateCustomerSeasonsAction = (customerId, seasons) => {
    return async (dispatch, getState) => {
        try {
            const customer = await Api.customer.saveCustomerSeasons(customerId, seasons)

            dispatch({
                type: TYPES.UPDATE_CUSTOMER_SEASON,
                payload: customer,
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: TYPES.UPDATE_CUSTOMER_SEASON_FAILED,
            })
        }
    }
}

export const createCustomerAccessAction = (customerId) => {
    return async (dispatch, getState) => {
        try {
            const customer = await Api.customer.grantCustomerAction(customerId)

            // alert('Customer has been given access using the email ' + customer.email)
            alert('Access is now Approved')

            dispatch({
                type: TYPES.CREATE_CUSTOMER_ACCESS_ACTION,
                payload: customer
            })
        } catch (e) {
            alert('Failed to give access to customer, please contact us');

            dispatch({
                type: TYPES.CREATE_CUSTOMER_ACCESS_ACTION_FAILED,
            })
        }
    }
}

export const forceVerifyCustomer = (customerId) => {
    return async (dispatch, getState) => {
        try {
            const customer = await Api.customer.forceVerify(customerId)

            // alert('Customer has been given access using the email ' + customer.email)
            alert('Access is now Approved')

            dispatch({
                type: TYPES.CUSTOMER_FORCE_ACCESS_GRANTED,
            })

            dispatch({
                type: TYPES.UPDATE_SINGLE_CUSTOMER,
                payload: customer
            })
        } catch (e) {
            alert('Failed to verify customer, please contact us');
        }
    }
}

export const revokeCustomerAccessAction = (customerId) => {
    return async (dispatch, getState) => {
        try {
            const customer = await Api.customer.revokeCustomerAccess(customerId)

            alert('Customer access has been revoked')

            dispatch({
                type: TYPES.REVOKE_CUSTOMER_ACCESS_ACTION,
            })

            dispatch({
                type: TYPES.UPDATE_SINGLE_CUSTOMER,
                payload: customer
            })
        } catch (e) {
            alert('Failed to revoke customer access, please contact us');

            dispatch({
                type: TYPES.REVOKE_CUSTOMER_ACCESS_ACTION_FAILED,
            })
        }
    }
}

export const forceAllowCustomerAccessAction = (customerId) => {
    return async (dispatch, getState) => {
        try {
            const customer = await Api.customer.forceAllowAccess(customerId)

            alert('Customer access has been granted access')

            dispatch({
                type: TYPES.CUSTOMER_FORCE_ACCESS_GRANTED,
            })
        } catch (e) {
            alert('Failed to force grant customer access, please contact us');

            dispatch({
                type: TYPES.CUSTOMER_FORCE_ACCESS_FAILED,
            })
        }
    }
}

export const getCustomerOrders = (customerId) => {
    return async (dispatch, getState) => {
        const orders = await Api.customer.getOrders(customerId)

        dispatch({
            type: TYPES.FETCH_CUSTOMER_ORDERS,
            payload: orders
        })
    }
}

export const clearCustomerOrders = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.CLEAR_CUSTOMER_ORDERS,
        })
    }
}

export const getCustomerOrdersByDate = (customerId, fromDate, toDate, session) => {
    return async (dispatch, getState) => {
        const orders = await Api.customer.getOrdersByDate(customerId, fromDate, toDate, session)

        dispatch({
            type: TYPES.FETCH_CUSTOMER_ORDERS,
            payload: orders
        })
    }
}

export const clearCustomerAfmExists = (customerId) => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.CLEAR_CUSTOMER_AFM_EXISTS,
        })
    }
}

export const createCustomerAction = (customerData, potentialCustomer = false, history) => {
    return async (dispatch, getState) => {
        const {customerCreated, payload} = await Api.customer.createCustomer(customerData, potentialCustomer)

        //Case customer created successfully
        if(customerCreated) {
            dispatch({
                type: TYPES.CREATED_CUSTOMER,
                payload
            })

            dispatch(selectCustomerAction(payload._id))

            history.push('/app/customer/account?new_customer=true')

        }else {
            //Found customer with the same afm
            if(payload && payload.length) {
                dispatch({
                    type: TYPES.CUSTOMER_AFM_EXISTS,
                    payload
                })

            }
            else if( payload && payload.keyPattern && payload.keyPattern.email ){
                alert('This email is already in use, please change the email or update the existing user!')
            }
            else {
                alert('Customer creation failed, please try again later')
            }
        }
    }
}

export const deSelectActiveCustomer = () => {
    return async(dispatch, getState) => {

        dispatch(setSelectedCustomer(null))

        dispatch({
            type: TYPES.LOGOUT_CUSTOMER,
        })

        dispatch(setProductPrices(true))
        dispatch(clearLivePrices())
        dispatch(clearCartActionInState())
        dispatch(clearWishlist())
    }
}

export const selectCustomerAction = (customerId) => {
    return async (dispatch, getState) => {

        dispatch(setSelectedCustomer(customerId))

        //set customer in api session


        // localStorage.setItem('vamp_salesapp_active_customer', customerId);
        //
        dispatch({
            type: TYPES.SELECT_CUSTOMER,
            payload: customerId,
        })

        dispatch(setProductPrices())
        dispatch(clearLivePrices())
        dispatch(getCartAction())
        dispatch(getSampleCartAction())
        dispatch(clearSetPrices())
        dispatch(getLivePricesForCustomersCart())
        dispatch(getWishlistItems())
    }
}

//Selects a customer from session or the logged in customer when in presentation mode
export const selectDefaultCustomerAction = () => {
    return async (dispatch, getState) => {

        const { auth: { activeUser } } = getState()

        // //Check if we have a selected customer from season
        // let selectedCustomerId = localStorage.getItem('vamp_salesapp_active_customer');

        let selectedCustomerId

        //Check if we are logged in as a customer
        if(activeUser) {
            switch (activeUser.role) {
                case 'customer': {
                    selectedCustomerId = activeUser.id
                    break;
                }
                case 'admin': {
                    selectedCustomerId = await Api.auth.getSelectedCustomer()
                    break;
                }
                case 'salesman': {
                    selectedCustomerId = await Api.auth.getSelectedCustomer()
                }
            }
        }

        dispatch({
            type: TYPES.SELECT_CUSTOMER_FROM_SESSION,
            payload: selectedCustomerId,
        })

        dispatch(clearLivePrices())
        dispatch(getCartAction())
        dispatch(getSampleCartAction())
        dispatch(clearSetPrices())
        dispatch(getLivePricesForCustomersCart())
        dispatch(getWishlistItems())
    }
}

export const getCustomerCountriesAction = (customer) => {
    return async (dispatch, getState) => {
        const countries = await Api.customer.getCountries()

        dispatch({
            type: TYPES.GET_COUNTRIES,
            payload: countries,
        })

    }
}

export const setDefaultAddress = (addressId, customerId) => {
    return async (dispatch, getState) => {
        const customerUpdated = await Api.customer.setDefaultAddress(addressId, customerId)

        dispatch({
            type: TYPES.DEFAULT_ADDRESS_SET,
            payload: customerUpdated,
        })
    }
}

const getLivePricesForCustomersCart = () => {
    return async (dispatch, getState) => {
        const { cart: { cartItems }, customer: { activeCustomer } } = getState()

        const productsIds = cartItems.map((cartItem) => {
            return cartItem.productId
        })

        if(activeCustomer) {
            dispatch(getLivePrices(productsIds, activeCustomer._id))
        }
    }
}

export const getCustomersAction = () => {
    return async(dispatch, getState) => {
        try {
            const customers = await Api.customer.getCustomers()

            dispatch({
                type: TYPES.FETCH_CUSTOMERS_SUCCESS,
                payload: customers
            })

            dispatch(selectDefaultCustomerAction())

        }catch (e) {
        }
    }
}

export const createAddressAction = (customerId, addressItem) => {
    return async(dispatch, getState) => {
        try {
            const customer = await Api.customer.createAddress(customerId, addressItem)

            dispatch({
                type: TYPES.UPDATE_CUSTOMER,
                payload: customer,
            })
        }catch (e) {
            console.log('Error in creating new customer address', e)
        }
    }
}

export const updateAddressAction = (customerId, addressItem) => {
    return async(dispatch, getState) => {
        try {
            const customer = await Api.customer.updateAddress(customerId, addressItem)

            dispatch({
                type: TYPES.UPDATE_CUSTOMER,
                payload: customer,
            })
        }catch (e) {
            console.log('Error in creating new customer address', e)
        }
    }
}
