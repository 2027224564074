import React, {Component} from 'react'
import {ReactComponent as CloseSvg} from '../assets/close.svg';
import {ReactComponent as EditSvg} from '../assets/edit.svg';

import './CartGridRow.scss'

class CartGridRow extends Component {
    constructor(props) {
        super(props)

        this.state = {
            stateVariations: [],
            imageWithColor: ''
        }
    }

    calculateRowQty(sizes) {
        let qty = 0;

        sizes.filter((size) => {
            let inputValue = parseInt(size.inputValue)

            if (inputValue) {
                qty += inputValue
            }
        });

        if (!qty) {
            return null
        }

        return qty
    }

    calculateRowPrice(item, currency) {
        let price = 0;

        if(item.variations && item.variations.length) {
            for(let i = 0; i < item.variations.length; i++) {
                price += parseFloat(item.variations[i].price) * parseInt(item.variations[i].inputValue)
            }
        }else {
            let itemInputQty = parseInt(item.inputValue)

            if(!itemInputQty) {
                itemInputQty = 1
            }

            price = item.price.final * itemInputQty
        }


        return `${currency}${price.toFixed(2)}`
    }

    componentDidMount() {
        const { item } = this.props

        let stateVariations = []

        if(item.type === 'variable') {
            stateVariations = item.variations
        }else {
            stateVariations.push({
                price: 0,
                barcode: item.code,
                inputValue: parseInt(item.inputValue),
            })
        }

        this.setState({
            stateVariations
        })

        if(item.additionalImagesWithAtt.length ){
            const imagesWithSelectedCode = item.additionalImagesWithAtt.filter( tempImage => tempImage.colorcode == item.colorCode )

            if( imagesWithSelectedCode.length ){
                const tempWord = item.image.split('/')
                const imageLine = item.image.replace(tempWord[tempWord.length - 1], imagesWithSelectedCode[0].filename)
                console.log(imageLine)
                this.setState({
                    imageWithColor: imageLine
                })
                console.log(this.state)
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.item.type === 'variable') {
            //Variable Product

            const prevPropsHash = JSON.stringify(prevProps.item.variations)
            const nextPropsHash = JSON.stringify(this.props.item.variations)

            if(prevPropsHash !== nextPropsHash) {
                this.setState({
                    stateVariations: this.props.item.variations
                })
            }
        }else {
            //Simple Product
            if(parseInt(prevProps.item.inputValue) !== parseInt(this.props.item.inputValue)) {
                //Simple Product
                this.setState({
                    stateVariations: [{
                        price: 0,
                        barcode: this.props.item.code,
                        inputValue: parseInt(this.props.item.inputValue),
                    }]
                })
            }
        }
    }

    findProductSizesSimoneCart() {
        const {stateVariations} = this.state
        const {product} = this.props
        let sizes = new Set()
        let cups = new Set()

        for (let i = 0; i < stateVariations.length; i++) {
            sizes.add(stateVariations[i].sizeName)
            cups.add(stateVariations[i].cupName)
        }

        return {
            sizesSimone: Array.from(sizes).sort(),
            cups: Array.from(cups),
        }
    }

    render() {
        const {sizesSimone, cups} = this.findProductSizesSimoneCart();
        const {item, handleInputChange, removeFromCart, openQuickView, currency, failedOrderItems} = this.props
        const {stateVariations} = this.state

        console.log(this.props)

        const colorBoxStyle = {
            background: `${item.colorHex}`,
        };

        let simoneTotal = 0
        let totalPriceSimone = 0

        stateVariations.map( ( stateVar ) => {
            simoneTotal += stateVar.inputValue;
            totalPriceSimone += stateVar.inputValue * stateVar.price
            return stateVar;
        } )

        return <tr className={`${item.foundInSearch ? 'item-found-in-search' : null}`}>
            <td className="cart-row">
                <div className="cart-image-sku-container">
                    <div className="cart-item-image">
                        <a data-fancybox="gallery" href={this.state.imageWithColor ? this.state.imageWithColor : item.image}>
                            <img src={this.state.imageWithColor ? this.state.imageWithColor : item.image} alt={item.code}/>
                        </a>
                    </div>
                </div>
            </td>
            <td className="cart-row">
                <div className="sku-container">
                    {item.code}
                </div>
                {item.type === 'variable' ?
                    <div className={ item.factory === "Simone" ? 'cart-row color-cell-container color-cell-container-simone' : "cart-row color-cell-container" }>
                        <div className="color-hex" style={colorBoxStyle}>
                        </div>
                        <div className="color-name-box">
                            <span className="color-code">{item.colorCode}</span>
                            <span className="color-code color-code-margin-left">{item.colorName.toLowerCase()}</span>
                        </div>
                    </div>
                    : null
                }
            </td>
            {
                item.factory === "Simone" ?
                    <td className='cart-td-stock-container'>
                        <div className='size-selector-header cart-simone-header'>
                            <div className='size-container-icon'>
                                <img className='size-icon' src='/CupSize.svg'/>
                            </div>
                            {sizesSimone.map((size, key) => <div
                                style={{width: `calc(((100% - 50px) / ${sizesSimone.length}))`}}
                                className='size-container' key={key}>
                                <p className='size-text'>
                                    {size}
                                </p>
                            </div>)}
                        </div>
                        {cups.map((cup, key) =>
                            <>
                                <div className='cart-cup-row'>
                                    <div className='cup-text-container'>
                                        <p className='cup-text'>
                                            {cup}
                                        </p>
                                    </div>
                            {sizesSimone.map((size, key) => {
                                const simoneVariation = stateVariations.filter( simoneVariation => simoneVariation.cupCode === cup && simoneVariation.sizeName === size )
                                console.log(simoneVariation)

                                    let realStockData = false

                                    if(failedOrderItems.length || simoneVariation.length) {
                                        for(let i = 0; i < failedOrderItems.length; i++) {
                                            if(failedOrderItems[i].key === simoneVariation[0].barcode) {
                                                realStockData = failedOrderItems[i].data
                                                break;
                                            }
                                        }
                                    }

                                return (simoneVariation && simoneVariation.length) ?
                                    <>
                                    <div style={{width: `calc(((100% - 50px) / ${sizesSimone.length}))`}}
                                         className='size-container' key={key}>
                                    <input
                                        className='cart-input-simone'
                                        min={0}
                                        type="number"
                                        disabled={simoneVariation[0].stock <= 0}
                                        value={simoneVariation[0].inputValue}
                                        onFocus={(e) => e.target.select()}
                                        onChange={(e) => {
                                            let valueRegulated = 0;

                                            for(let i = 0; i < stateVariations.length; i++) {

                                                const inputQty = parseInt(e.target.value)
                                                const variationStock = parseInt(simoneVariation[0].stock)

                                                if(stateVariations[i].barcode === simoneVariation[0].barcode) {
                                                    //Stock check
                                                    if(inputQty > variationStock) {
                                                        stateVariations[i].inputValue = variationStock
                                                    }else {
                                                        stateVariations[i].inputValue = inputQty
                                                    }

                                                    valueRegulated = stateVariations[i].inputValue
                                                    break
                                                }
                                            }
                                            this.setState({stateVariations})
                                            handleInputChange(item._id, simoneVariation[0].barcode, valueRegulated, item.markedAsPreorder)
                                        }}/>
                                        {simoneVariation[0].stock === 999 || (typeof simoneVariation[0].stock === 'undefined')
                                            ? null :
                                            <div className="stock-qty">
                                                <span>Stock: {parseInt(simoneVariation[0].stock > 10 ? 10 : simoneVariation[0].stock)}</span>
                                                {realStockData ? <span className="live-stock-availability">Stock: {realStockData.available > 10 ? 10 : realStockData.available}</span> : null }
                                            </div>
                                        }
                                    </div></> : <div style={{width: `calc(((100% - 50px) / ${sizesSimone.length}))`}}
                                                  className='size-container'></div>
                            }
                            )}
                            </div>
                            </>
                        )}
                    </td>
                    :
                    <td className="cart-row size-cell-container cart-td-stock-container">
                        {stateVariations.map((variation) => {

                            let realStockData = false

                            if(failedOrderItems.length) {
                                for(let i = 0; i < failedOrderItems.length; i++) {
                                    if(failedOrderItems[i].key === variation.barcode) {
                                        realStockData = failedOrderItems[i].data
                                        break;
                                    }
                                }
                            }

                            return <div className={`size-item ${realStockData ? 'missing-stock' : ''}`} key={variation.barcode}>
                                <div className="size-name">{variation.sizeName}</div>
                                <input
                                    min={0}
                                    type="number"
                                    disabled={variation.stock <= 0}
                                    value={variation.inputValue}
                                    onFocus={(e) => e.target.select()}
                                    onChange={(e) => {
                                        let valueRegulated = 0;

                                        for(let i = 0; i < stateVariations.length; i++) {

                                            const inputQty = parseInt(e.target.value)
                                            const variationStock = parseInt(variation.stock)

                                            if(stateVariations[i].barcode === variation.barcode) {
                                                //Stock check
                                                if(inputQty > variationStock) {
                                                    stateVariations[i].inputValue = variationStock
                                                }else {
                                                    stateVariations[i].inputValue = inputQty
                                                }

                                                valueRegulated = stateVariations[i].inputValue
                                                break
                                            }
                                        }
                                        this.setState({stateVariations})
                                        handleInputChange(item._id, variation.barcode, valueRegulated, item.markedAsPreorder)
                                    }}/>
                                {/*Preorder stock check*/}
                                {variation.stock === 999 || (typeof variation.stock === 'undefined')
                                    ? null :
                                    <div className="stock-qty">
                                        <span>Stock: {parseInt(variation.stock > 10 ? 10 : variation.stock)}</span>
                                        {realStockData ? <span className="live-stock-availability">Stock: {realStockData.available > 10 ? 10 : realStockData.available}</span> : null }
                                    </div>
                                }
                            </div>
                        })}
                    </td>
            }
            <td className='cart-row cart-row-total-qty'>
                <div
                    className={'total-row-container-cart margin-bottom-zero'}>
                    <div className='totat-price-container'>
                        <p className='total-price-text'>
                            {simoneTotal}
                            <br/>
                            <span className='cart-margin-top cart-simone-price'>
                                        {this.props.currency} {totalPriceSimone.toFixed(2)}
                            </span>
                        </p>
                    </div>
                </div>
            </td>
            <td className="cart-row">
                <div
                    className='cart-edit-button'
                    onClick={() => {
                        //True to open for edit
                        openQuickView(item._id, true)
                    }}>
                    Edit
                </div>
                <div
                    className='cart-remove-button'
                    onClick={() => {
                        if(window.confirm('Are you sure about deleting this product from cart?')) {
                            if(item.type === 'simple') {
                                //All posters are preorders
                                removeFromCart([item.code], true)
                            }else {
                                const barcodes = item.variations.map((variation) => variation.barcode)
                                removeFromCart(barcodes, item.markedAsPreorder)
                            }
                        }
                    }}>
                    Remove
                </div>
            </td>
        </tr>
    }
}

export default CartGridRow
