import React, {Component, Fragment} from 'react'
import CustomerListItem from "./CustomerListItem";
import CustomerAccessData from "./Access/CustomerAccessData";
import { withTranslation } from 'react-i18next';

import './CustomerList.scss'

class CustomerList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {customers} = this.props

        if (customers && customers.length !== prevProps.customers.length) {
            this.setState({
                page: 1
            })
        }
    }

    printPagination() {
        const {customers, perPage} = this.props;
        const {page} = this.state;

        let paginationCount = Math.ceil(customers.length / perPage)
        let paginationArray = []

        for (let i = 1; i <= paginationCount; i++) {
            paginationArray.push(i)
        }

        const leftLimit = page - 12;
        const rightLimit = page + 12

        return paginationArray.map((number, key) => {

            if ((number < leftLimit || number > rightLimit) && key > 0 && number !== paginationCount) {
                return null
            }

            return <li onClick={() => this.setState({page: number})} key={number}
                       className={`pagination-number ${(number === page) ? 'active' : ''}`}>
                {number}
            </li>
        })
    }

    printPaginatedCustomers(customers) {
        const {page} = this.state
        const {
            perPage,
            handleCustomerSelect,
            accessData,
            seasons,
            updateCustomerSeasons,
            createCustomerAccess,
            forceVerifyCustomer,
            revokeCustomerAccess,
            forceAllowCustomerAccess,
            updateEmail,
            updateMobile,
            customersSoftoneIdsWithSeasonOrders,
            role,
            activeUserCountryCode,
        } = this.props

        let showLimit = (page * perPage) - 1
        let firstLimit = ((page - 1) * perPage)

        let seasonsArray = []

        if (seasons && seasons.length) {
            seasonsArray = seasons.map(season => {
                return {
                    value: season.softoneId,
                    label: season.name
                }
            })
        }

        return customers.sort((a, b) => {
            const textA = a.name.toLowerCase()
            const textB = b.name.toLowerCase()

            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).map((customer, key) => {
            if (key > showLimit || key < firstLimit) {
                return null
            }

            return accessData ?
                <CustomerAccessData
                    onClick={() => handleCustomerSelect(customer)}
                    key={customer._id}
                    customer={customer}
                    seasonsArray={seasonsArray}
                    updateCustomerSeasons={updateCustomerSeasons}
                    createCustomerAccess={createCustomerAccess}
                    forceVerifyCustomer={forceVerifyCustomer}
                    revokeCustomerAccess={revokeCustomerAccess}
                    forceAllowCustomerAccess={forceAllowCustomerAccess}
                    updateEmail={updateEmail}
                    updateMobile={updateMobile}
                    customersSoftoneIdsWithSeasonOrders={customersSoftoneIdsWithSeasonOrders}
                    role={role}
                    activeUserCountryCode={activeUserCountryCode}
                />
                : <CustomerListItem
                    onClick={() => handleCustomerSelect(customer)}
                    key={customer._id}
                    customer={customer}
                />
        })
    }

    printTableHeader(role) {

        const { activeUserCountryCode } = this.props
        const { t } = this.props;

        return <tr>
            <th>{t('Name')}</th>
            <th>{t('Email')}</th>
            {activeUserCountryCode !== 1000 ? null : <th>{t('Mobile')}</th>}
            <th>{t('Code')}</th>
            <th>{t('Potential')}</th>
            <th>{t('Can Preorder')}</th>
            <th>{t('Order')}Order</th>
            <th>{t('Preorder Until')}Preorder Until</th>
            <th>{t('Preorder Authenticated')}</th>
            <th>{t('Access')}</th>
            <th>{t('Verify')}</th>
        </tr>

    }

    render() {
        const { t } = this.props;
        const {customers, accessData, role} = this.props

        if (!customers || !customers.length) {
            return <div className="no-customers-found-msg">{t('No customers found')}</div>;
        }

        return (
            <div className="customers-list-container">
                {accessData ?
                    <table className="customers-access-table">
                        <tbody>
                        {this.printTableHeader(role)}
                        {this.printPaginatedCustomers(customers)}
                        </tbody>
                    </table>
                    :
                    <ul className="customers-list">
                        {this.printPaginatedCustomers(customers)}
                    </ul>
                }

                <ul className="pagination-component">
                    {this.printPagination()}
                </ul>
            </div>
        );
    }
}

export default withTranslation()(CustomerList)
