import React from 'react'
import { connect } from 'react-redux'
import './AdminMenu.scss'

import { updateMenuAction, updateMenuActionCZ } from '../../actions/settingsActions'

import MenuEditor from "./MenuEditor";

const AdminMenu = ({ updateMenuAction, settings, updateMenuActionCZ }) => {

    return <div className="admin-page admin-menu-editor">
        <h3 className="admin-page-menu-header">
            Menu GR
        </h3>
        <MenuEditor saveMenu={updateMenuAction} menuItems={settings.settings.menuItems}/>
        <h3 className="admin-page-menu-header">
            Menu CZ
        </h3>
        <MenuEditor saveMenu={updateMenuActionCZ} menuItems={settings.settings.menuItemsCZ}/>
    </div>
}

const mapStateToProps = ({ settings, auth}) => {
    return {
        settings,
        auth,
    }
}

export default connect(mapStateToProps, { updateMenuAction, updateMenuActionCZ })(AdminMenu)