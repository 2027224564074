import Api from "../controllers/Api";
import { clearCartAction, getSampleCartAction } from './cartActions'
import {getCustomerOrders, getCustomersAction} from "./customerActions";

export const TYPES = {
    SUBMIT_ORDER_SUCCESS: 'SUBMIT_ORDER_SUCCESS',
    SUBMIT_ORDER_FAILED: 'SUBMIT_ORDER_FAILED',
    CLEAR_ORDER_FAIL_MESSAGE: 'CLEAR_ORDER_FAIL_MESSAGE',
    SAVE_ORDER_SUCCESS: 'SAVE_ORDER_SUCCESS',
    SAMPLES_ORDER_SENT: 'SAMPLES_ORDER_SENT',
    SAMPLES_ORDER_FAILED: 'SAMPLES_ORDER_FAILED',
    CLEAR_SUBMITTED_ORDER_ID: 'CLEAR_SUBMITTED_ORDER_ID',
    CLEAR_CART_SAMPLE: 'CLEAR_CART_SAMPLE',
}

export const saveOrderAction = () => {
    return async (dispatch, getState) => {
        const {
            customer: { activeCustomer } ,
            cart: { cartItems } ,
            order: { activeSavedOrderId }
        } = getState()

        //Check if we have an active order id
        const submittedOrderId = await Api.order.save(activeCustomer._id, cartItems, activeSavedOrderId)

        alert("Your order has been saved")

        dispatch({
            type: TYPES.SAVE_ORDER_SUCCESS,
            payload: submittedOrderId
        })

        dispatch(clearCartAction())
    }
}

export const clearSubmittedOrder = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: TYPES.CLEAR_SUBMITTED_ORDER_ID,
        })
    }
}

export const checkoutSamplesAction = (samplesList, userId, customerId) => {

    return async (dispatch, getState) => {
        try {
            const submittedOrderId = await Api.order.sendSamples(
                samplesList,
                userId,
                customerId
            )

            if(submittedOrderId.message === "Error") {
                dispatch(getSampleCartAction())
                alert("You have added samples with with 0 stock. The samples are removed from your list. Please submit again!")
                window.location.href='/app';
            }

            dispatch({
                type: TYPES.SUBMIT_ORDER_SUCCESS,
                payload: submittedOrderId.payload.orderId
            })

            dispatch({
                type: TYPES.CLEAR_CART_SAMPLE
            })

        }catch (e) {
            throw new Error('Failed to submit sample order')
            dispatch({
                type: TYPES.SAMPLES_ORDER_FAILED,
                payload: e
            })
        }
    }
}

export const checkoutAction = (shippingAddressId, extraOrderData) => {
    return async (dispatch, getState) => {

        const {
            customer: { activeCustomer } ,
            cart: { cartItems } ,
            order: { activeSavedOrderId, editMode }
        } = getState()

        try {
            //Send data to the api, send the active saved order id if exists
            const submittedOrderId = await Api.order.send(
                shippingAddressId,
                activeCustomer._id,
                cartItems,
                extraOrderData,
                activeSavedOrderId,
                editMode,
            )

            if(submittedOrderId.softoneId) {
                dispatch({
                    type: TYPES.SUBMIT_ORDER_SUCCESS,
                    payload: submittedOrderId.orderId
                })

                dispatch(clearCartAction())
                dispatch(getCustomersAction())
                dispatch(getCustomerOrders(activeCustomer._id))
            }else {
                // dispatch({
                //     type: TYPES.SUBMIT_ORDER_FAILED,
                // })
                //
                // dispatch(clearCartAction())
            }
        }catch (e) {
            if(e.response && e.response.data && e.response.data.payload) {
                dispatch({
                    type: TYPES.SUBMIT_ORDER_FAILED,
                    payload: e.response.data.payload
                })
            }
        }
    }
}
